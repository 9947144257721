import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const FormWrapper = styled.div`
  min-height: 100vh;
  width: 90vw;
  max-width: 500px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;

  form {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  span {
    border: 1px solid red;
    padding: .5rem;
    border-radius: 4px;
    margin-bottom: 1rem;
  }

  input, textarea {
    font-size: 18px;
    padding: 1rem;
    border-radius: 4px;
    border: 0.0625rem solid #dbdbdb;
    width: 100%;
    box-sizing: border-box;
    outline: 0;
    transition: all 0.2s ease-in-out;

    &:focus {
      border:  0.0625rem solid black;
    }
  }
`;

const Button = styled.button`
  padding: 1rem;
  background: black;
  color: white;
  font-weight: 700;
  width: 100%;
  border-radius: 4px;
  font-size: 18px;
  box-shadow: 0 4px 6px rgba(50,50,93,.11), 0 1px 3px rgba(0,0,0,.08);
  background: #black;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  text-transform: uppercase;

  &:hover {
    transform: translate(0, -2px);
    color: black;
    background: white;
  }
`;

const Back = styled.div`
  position: fixed;
  top: 2rem;
  left: 2rem;

  a {
    color: black;
    text-decoration: none;
  }
`;

const Contact = (props) => (
  <FormWrapper>
    <Back><Link to='/'>Back</Link></Back>
    <form name="contact" method="post">
        <input type="hidden" name="form-name" value="contact" />
        <p>
          <input placeholder="Name" type="text" name="name"/>
        </p>
        <p>
          <input type="email" placeholder="Email" name="email"/>
        </p>
        <p>
          <textarea rows='6' placeholder="Enter your message …" name="message"></textarea>
        </p>
        <p>
          <Button type="submit">Send</Button>
        </p>
    </form>
  </FormWrapper>
);

export default Contact;
