import React from 'react';
import styled from 'styled-components';
import Logo from '../components/Logo';
import Subscribe from '../components/Subscribe';
import { device } from '../utils/devices';
import { Link } from 'react-router-dom';

const Wrapper = styled.div`
  min-height 100vh;

  @media ${device.laptop}{
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
`;

const Headline = styled.h1`
  font-size: 48px;
  margin-top: 0;
`;

const Subhead = styled.div`
  font-weight: 300;
  font-size: 20px;
  line-height: 1.6em;
`;

const Section = styled.section`
  padding: 0 5vw;
  position: relative;

  @media ${device.laptop}{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &:last-of-type {
      background: rgba(0,0,0,0.03);
    }
  }
`;

const Contact = styled.div`
  margin: 2rem 0;

  a {
    color: black;
    text-transform: uppercase;
    text-decoration: none;
  }
`;

const Home = (props) => (
  <Wrapper>
    <Section>
      <Logo />
    </Section>
    <Section>
      <Headline>Everything in this world was created by people who were no smarter than you.</Headline>
      <Subhead>
        <p>There are no chosen ones. Every human was born with genius level talent.</p>
        <p>This year we’ll be hosting a series of working retreats for Creatives. An opportunity to connect, collaborate, learn from each other, and invent the future. Sign up for updates.</p>
        <p>Launching Spring 2019.</p>
        <Subscribe />
      </Subhead>
      <Contact><Link to='/contact'>Contact</Link></Contact>
    </Section>
  </Wrapper>
);

export default Home;
