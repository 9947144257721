import React from 'react';
import styled from 'styled-components';
import img from '../images/logo.png';

const Wrapper = styled.div`
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

const Logo = (props) => (
  <Wrapper>
    <img src={img} alt='Genius Level Talent logo' />
  </Wrapper>
);

export default Logo;
