import React, { Component } from 'react';
import styled from 'styled-components';
import { device } from '../utils/devices';

const Form = styled.form`
  width: 100%;
  margin: 2rem 0 4rem;

  @media ${device.laptop}{
    display: grid;
    margin: 2rem 0 0;
    grid-template-columns: 2fr 1fr;
  }

  input {
    font-size: 18px;
    font-family: 'FK', Helvetica, sans-serif;
    padding: 1.5rem;
    appearance: none;
    border: 0;
    border: 1px solid rgba(0,0,0,0.12);
    box-sizing: border-box;
    width: 100%;
  }

  button {
    background: black;
    padding: 1.5rem;
    color: white;
    font-size: 18px;
    text-transform: uppercase;
    font-weight: 600;
    border: 0;
    appearance: none;
    cursor: pointer;
    border: 1px solid black;
    transition: .2s all ease-in-out;
    box-sizing: border-box;
    width: 100%;

    &:hover {
      color: black;
      background: white;
    }
  }
`;

const Error = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  box-sizing: border-box;
  background: red;
  color: white;
  text-align: center;
  padding: 1rem;
  font-size: 16px;
`;

class Subscribe extends Component {

  state = {
    email: ''
  }

  onChange = (e) => {
    this.setState({ email: e.target.value })
  }

  render() {
    return (
      <Form action="https://trapkaraoke.us11.list-manage.com/subscribe/post?u=fe546870f7250a20ecfbd84e4&amp;id=cedef5ec24" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" className="validate" target="_blank" novalidate>
        <input onChange={this.onChange} type="email" value={this.state.email} name="EMAIL" className="required email" id="mce-EMAIL" />
        <button>Subscribe</button>
      </Form>
    );
  }

}

export default Subscribe;
